import merge from 'lodash/merge'
import type { FunctionComponent } from 'react'
import type { DeepPartial } from 'react-hook-form'

import { PageLoadingState } from '../contentful-components/loading-state'
import type { PageMetaProps } from '../contentful-components/page-meta'
import { PageMeta } from '../contentful-components/page-meta'
import { SectionResolver } from '../contentful-components/section-resolver'
import type { PageMetaFragment } from '../graphql/fragments/PageMetaFragment.graphql'
import type { PageHookOptions } from '../graphql/hooks/page'
import { usePage } from '../graphql/hooks/page'
import { EMPTY_COLLECTION } from '../graphql/placeholders'
import type { PageFragment } from '../graphql/queries/GetPage.graphql'
import { useMode } from '../lib/hooks/useMode'
import { PersonalizationProvider } from '../lib/hooks/usePersonalizations'
import { suspense } from '../lib/utils/suspense'
import type { WithTestId } from '../lib/utils/testid'
import type { ThemeMode } from '../lib/utils/theme'

export const PageTemplate: FunctionComponent<PageTemplateProps> = (props) => {
    if (props.pageData) {
        return <PageTemplateResolved {...props} />
    }
    return <SelfFetchingPageTemplate {...props} />
}

const SelfFetchingPageTemplate: FunctionComponent<PageTemplateProps> = suspense(
    (props) => {
        const { slug, includeBanner, includeBody } = props

        const { page, exists } = usePage({
            slug,
            includeBanner,
            includeBody,
        })

        if (!exists) {
            return null
        }

        return <PageTemplateResolved {...props} pageData={page} />
    },

    <PageLoadingState />
)

/**
 * Creates basic page layout with sections pulled from Contentful.
 */
const PageTemplateResolved: FunctionComponent<PageTemplateProps> = ({
    pageData: page,
    'data-testid': testId,
    overrides,
    meta: metaProps,
    children = null,
}) => {
    const {
        banner,
        mode,
        sections: { items: fragments } = EMPTY_COLLECTION,
        meta,
    } = merge({}, page, overrides)

    // if banner, just add as the first section in the list
    const sectionsWithBanner = banner
        ? [{ preset: 'primary', ...banner }, ...fragments]
        : fragments

    useMode(mode as ThemeMode)

    return (
        <PersonalizationProvider value={{}}>
            {meta && (
                <PageMeta
                    id={meta.sys.id}
                    {...metaProps}
                    name={metaProps?.name ?? page?.slug ?? ''}
                    meta={meta}
                />
            )}
            <div data-testid={testId}>
                <SectionResolver sections={sectionsWithBanner} />
            </div>
            {children}
        </PersonalizationProvider>
    )
}

export type PageTemplateProps = WithTestId<
    PageHookOptions & {
        children?: React.ReactNode
        meta?: Omit<PageMetaProps, 'id'>

        /** Optional overrides for the components */
        overrides?: DeepPartial<PageFragment>

        pageData?: PageFragment & { meta?: PageMetaFragment }
    }
>
