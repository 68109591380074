import type { GetServerSideProps, GetStaticProps, NextPage } from 'next'

import { PageTemplate } from '../../page-templates/generic-page'
import type { PageOption } from '../utils/page'
import { fetchContentfulPage } from './fetchStaticPage'
import type { StaticPageData } from './page'

export const DEFAULT_PAGE_TTL = 60 * 60 * 1 // 1 hour

/**
 * Creates basic page with sections pulled from static JSON files. You can find
 * all rendered sections at ./registry. Unknown sections will be ignored. If you
 * need to extend this, just put it into a variable and use like this:
 *
 * ```tsx
 * const Page = createStaticPage({ slug: 'test', name: 'Test' })
 *
 * const MyCustomPage: NextPage<StaticPageData> = (pageProps) => (
 *     <>
 *         <Page {...pageProps} />
 *         <OtherSection />
 *     </>
 * )
 * ```
 */
export function createStaticPage({
    meta,
    'data-testid': testId,
}: PageOption): NextPage<StaticPageData> {
    const { name } = meta

    const Page: NextPage<StaticPageData> = ({ data, slug }) => {
        // This seems to be necessary because `data` is not available on first render
        if (!data) return null

        return <PageTemplate data-testid={testId} slug={slug} pageData={data} />
    }

    Page.displayName = name

    return Page
}

/**
 * returns a next.js getStaticProps function that will be invoked on page build
 * and returns the data from contentful for the provided page slug
 */
export function getContenfulStaticProps(
    slug: string,
    name?: string,
    options?: { revalidate?: number }
) {
    const getStaticProps: GetStaticProps = async (context) => {
        const data = await fetchContentfulPage(slug)

        return {
            props: {
                slug: slug ?? context?.params?.slug,
                data,
                name: name ?? slug,
            },
            revalidate: options?.revalidate ?? DEFAULT_PAGE_TTL,
        }
    }

    return getStaticProps
}

/**
 * returns a next.js getServerSideProps function that will be invoked on page build
 * and returns the data from contentful for the provided page slug
 */
export function getContenfulServerSideProps(page: PageOption, name?: string) {
    const { slug, feature } = page

    const getServerSideProps: GetServerSideProps = async (context) => {
        // wrap in try catch to fail over to 404 page if the fetch from contentful results in no page being found
        try {
            // fetch the inistial page object by slug
            const data = await fetchContentfulPage(slug)

            return {
                props: {
                    slug: slug ?? context?.params?.slug,
                    feature: feature ?? null,
                    data,
                    name: name ?? slug,
                },
            }
        } catch {
            return {
                notFound: true,
            }
        }
    }

    return getServerSideProps
}
